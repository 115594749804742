export default {
    name: '参观记录',
    listTitle: '参观记录列表',
    url: '/visit-record', // rest接口表名
    query: '&sort-id', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '来访时间', dataIndex: 'visit_time'},
            {title: '来访单位', dataIndex: 'institution'},
            {title: '来访人数', dataIndex: 'people_count'},
        ]
    },
    // 列表查询表单
    queryForm: [{
        key: 'institution',
        label: '',
        component: 'a-input',
        props: {
            placeholder: '请输入来访单位查询',
        },
    }],
    searchKeyType: {institution: true},
    // 新增/编辑查询表单
    items: [
        {
            key: 'visit_time',
            label: '来访时间',
            component: 'a-date-picker',
            props: {
                showTime: {format: 'HH:mm'},
                format: 'YYYY-MM-DD HH:mm',
                valueFormat: 'YYYY-MM-DD HH:mm',
                placeholder: '请选择来访时间'
            },
            rules: [{ required: true, message: '请选择来访时间', trigger: 'change' }]
        },
        {
            key: 'institution',
            label: '来访单位',
            component: 'a-input',
            props: {
                placeholder: '请输入来访单位',
            },
            rules: [{ required: true, message: '请输入来访单位', trigger: 'blur' }]
        },
        {
            key: 'people_count',
            label: '来访人数',
            component: 'a-input-number',
            props: {
                placeholder: '请输入来访人数',
            },
            style: {
                width: '240px'
            },
            rules: [{ required: true, message: '请选择来访人数', trigger: 'blur' }]
        },
        {
            key: 'info',
            label: '来访事由',
            component: 'a-textarea',
            props: {
                placeholder: '请输入来访事由',
                rows: 4
            },
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '图片',
                key: 'img',
                span: 3,
                slot: entity => {
                    return <img class="avatar" src={entity.img}/>
                }
            },
            {
                label: '事迹介绍',
                key: 'info',
                span: 3
            },
        ]
    },
}
