import group from "./group";
import site from "./site";
import activity from './activity';
import policy from "./policy";
import course from "./course";
import teacher from "./teacher";
import banner from "./banner";
import notice from "./notice";
import live from "./live";
import story from "./story";
import visit from "./visit";
export default {
    group,
    site,
    activity,
    policy,
    course,
    banner,
    notice,
    teacher,
    live,
    story,
    visit
}
