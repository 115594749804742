import {getTimePeriod} from "../js/tool";

export default {
    name: '直播',
    listTitle: '直播列表',
    url: '/stream', // rest接口表名
    query: '&sort=order,-id', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '标题', dataIndex: 'title'},
            {
                title: '横幅',
                dataIndex: 'banner',
                customRender: text => {
                    return <img class="avatar" src={text} />
                }
            },
            {
                title: '时间',
                dataIndex: 'start_time',
                customRender: (text, record) => {
                    return <span>{getTimePeriod(record.start_time, record.end_time)}</span>
                }
            },
        ]
    },
    // 列表查询表单
    queryForm: [{
        key: 'title',
        label: '',
        component: 'a-input',
        props: {
            placeholder: '请输入直播标题查询',
        },
    }],
    // 新增/编辑查询表单
    items: [
        {
            key: 'title',
            label: '直播标题',
            component: 'a-input',
            props: {
                placeholder: '请输入直播标题',
            },
            rules: [{ required: true, message: '请输入直播标题', trigger: 'blur' }]
        },
        {
            key: 'banner',
            label: '直播图片',
            component: 'form-upload',
            rules: [{ required: true, message: '请上传横幅图片', trigger: 'change' }]
        },
        {
            key: 'time',
            label: '直播时间',
            component: 'a-range-picker',
            props: {
                showTime: {format: 'HH:mm'},
                format: 'YYYY-MM-DD HH:mm',
                valueFormat: 'YYYY-MM-DD HH:mm',
                placeholder: ['开始时间', '结束时间']
            },
            listeners: {
                change(val) {
                    if(val && val.length > 1) {
                        this.$set(this.form, 'start_time', val[0]);
                        this.$set(this.form, 'end_time', val[1]);
                    } else {
                        this.$set(this.form, 'start_time', null);
                        this.$set(this.form, 'end_time', null);
                    }
                }
            },
            rules: [{ required: true, message: '请设置直播时间', trigger: 'change' }]
        },
        {
            key: 'list',
            label: '直播列表',
            component: 'live-config',
            listeners: {
                change(val) {
                    this.$set(this.form, 'list_config',  val ? JSON.stringify(val) : null);
                }
            },
            rules: [{ required: true, message: '请设置直播列表', trigger: 'change' }]
        },
        {
            key: 'info',
            label: '其他介绍',
            component: 'rich-editor'
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '直播标题',
                key: 'title'
            },
            {
                label: '直播图片',
                key: 'img',
                slot: entity => {
                    return <img class="avatar" src={entity.banner}/>
                }
            },
            {
                label: '直播时间',
                key: 'time',
                slot: entity => {
                    return <span>{getTimePeriod(entity.start_time, entity.end_time)}</span>
                }
            },
            {
                label: '直播列表',
                key: 'time',
                slot: entity => {
                    return entity.list ? <div class="live-config-list">
                        {entity.list.map(live => {
                            return <div class="live-config-item">
                                <div class="live-config-img" style={{backgroundImage: `url(${live.img})`}}></div>
                                <div class="live-config-title">{live.title}</div>
                                {live.time ? <div class="live-config-time">{`${live.time[0]} - ${live.time[1]}`}</div> : null}
                            </div>
                        })}
                    </div> : '无'
                }
            },
        ]
    },
    // 编辑实体前处理函数
    beforeEdit: function(detail) {
        return new Promise(resolve => {
            if(detail.start_time && detail.end_time) {
                detail.time = [detail.start_time, detail.end_time];
            }
            resolve();
        })
    },
    dealDetail: info => new Promise(resolve => {
        if(info.list_config) {
            try {
                info.list = JSON.parse(info.list_config);
            } catch (e) {
                console.error(e);
            }
        }
        resolve(info);
    })
}
