export default {
    name: '场地',
    listTitle: '场地列表',
    url: '/site', // rest接口表名
    query: '&sort=order,-id', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '场地名称', dataIndex: 'name'},
            {title: '场地联系人', dataIndex: 'linkman'},
            {title: '容纳人数', dataIndex: 'people_count'},
            {title: '排序', dataIndex: 'order'},
        ]
    },
    // 列表查询表单
    queryForm: [{
        key: 'name',
        label: '',
        component: 'a-input',
        props: {
            placeholder: '请输入场地名称查询',
        },
    }],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '场地名称',
            component: 'a-input',
            props: {
                placeholder: '请输入场地名称',
            },
            rules: [{ required: true, message: '请输入场地名称', trigger: 'blur' }]
        },
        {
            key: 'linkman',
            label: '场地联系人',
            component: 'a-input',
            props: {
                placeholder: '请输入场地联系人',
            },
            rules: [{ required: true, message: '请输入场地联系人', trigger: 'blur' }]
        },
        {
            key: 'people_count',
            label: '容纳人数',
            component: 'a-input-number',
            props: {
                placeholder: '请输入容纳人数',
            },
            style: {
                width: '240px'
            },
            rules: [{ required: true, message: '请输入容纳人数', trigger: 'blur' }]
        },
        {
            key: 'tel',
            label: '咨询电话',
            component: 'a-input',
            props: {
                placeholder: '请输入咨询电话',
            },
            rules: [{ required: true, message: '请输入咨询电话', trigger: 'blur' }]
        },
        {
            key: 'timeConfig',
            label: '预约设置',
            component: 'time-period',
            listeners: {
                change(val) {
                    if(val) {
                        this.$set(this.form, 'time_config', JSON.stringify(val));
                    } else {
                        this.$set(this.form, 'time_config', null);
                    }
                }
            },
            rules: [{ required: true, message: '请设置预约时间段', trigger: 'change' }]
        },
        {
            key: 'is_skip_holiday',
            label: '是否跳过节假日',
            component: 'a-radio-group',
            options: [
                {value: 1, text: '是'},
                {value: 0, text: '否'},
            ],
            rules: [{ required: true, message: '请选择', trigger: 'change' }]
        },
        {
            key: 'img',
            label: '场地图片',
            component: 'form-upload',
            props: {
                limit: 9
            },
            rules: [{ required: true, message: '请上传专辑封面', trigger: 'change' }]
        },

        {
            key: 'order',
            label: '排序',
            component: 'a-input-number',
            props: {
                placeholder: '数字越小排位越前',
            },
            style: {
                width: '240px'
            },
        },
    ],
    // 新增实体初始化字段
    init: {},
    // 详情接口expand字段
    expand: '',
    // 详情自定义渲染
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '容纳人数',
                key: 'people_count'
            },
            {
                label: '场地联系人',
                key: 'linkman'
            },
            {
                label: '排序',
                key: 'order'
            },
            {
                label: '咨询电话',
                key: 'tel'
            },
            {
                label: '场地图片',
                key: 'img',
                slot: entity => {
                    return <img class="avatar" src={entity.img}/>
                }
            },
            {
                label: '预约时间段',
                key: 'timeConfig',
                slot: entity => {
                    return entity.timeConfig ? <div>
                        <div>{entity.timeConfig ? entity.timeConfig.map(item => {
                            return <div>{item.start} - {item.end}</div>
                        }) : null}</div>
                    </div> : <span>无</span>
                }
            },
            {
                label: '跳过节假日',
                key: 'is_skip_holiday',
                slot: entity => {
                    return <span>{entity.is_skip_holiday == 1 ? '是' : '否'}</span>
                }
            },
        ]
    },
    beforeEdit: function(detail) {
        return new Promise(resolve => {
            if(detail.time_config && !detail.timeConfig) {
                detail.timeConfig = JSON.parse(detail.time_config);
            }
            resolve();
        })
    },
    dealDetail: info => new Promise(resolve => {
        if(info.time_config) {
            info.timeConfig = JSON.parse(info.time_config);
        }
        resolve(info);
    })
}
