export const getActivityStatus = function (time) {
    let now = Date.now();
    let start = new Date(time);
    return now >= start ? 2 : 1;
}
const getActivityStatusText = function (time, status) {
    status = status || getActivityStatus(time);
    return [null, '报名中', '报名结束'][status];
}


export default {
    name: '活动',
    listTitle: '活动列表',
    url: '/activity', // rest接口表名
    query: '&sort=order,-id&expand=enrollNum,group', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '发布时间', dataIndex: 'start_time'},
            {title: '活动名称', dataIndex: 'name'},
            {
                title: '活动人数',
                customRender: (text, record) => {
                    return <span>{record.enrollNum+Number(record.add_people_num)}</span>
                }
            },
            {
                title: '状态',
                customRender: (text, record) => {
                    return <span>{getActivityStatusText(record.start_time)}</span>
                }
            },
            {title: '排序', dataIndex: 'order'},
        ]
    },
    // 列表查询表单
    queryForm: [{
        key: 'name',
        label: '',
        component: 'a-input',
        props: {
            placeholder: '请输入活动名称查询',
        },
    }],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '活动名称',
            component: 'a-input',
            props: {
                placeholder: '请输入活动名称',
            },
            rules: [{ required: true, message: '请输入活动名称', trigger: 'blur' }]
        },
        {
            key: 'start_time',
            label: '活动时间',
            component: 'a-date-picker',
            props: {
                showTime: {format: 'HH:mm'},
                format: 'YYYY-MM-DD HH:mm',
                valueFormat: 'YYYY-MM-DD HH:mm',
                placeholder: '请选择活动时间'
            },
            rules: [{ required: true, message: '请选择活动时间', trigger: 'change' }]
        },
        {
            key: 'location',
            label: '活动地点',
            component: 'a-input',
            props: {
                placeholder: '请输入活动地点',
            },
            rules: [{ required: true, message: '请输入活动地点', trigger: 'blur' }]
        },
        {
            key: 'group',
            label: '组织',
            component: 'a-select',
            entity: 'group',
            props: {
                options: [],
                placeholder: "请选择组织"
            },
            style: {
                width: '240px'
            },
            listeners: {
                change(val) {
                    if(val == 0) {
                        this.$set(this.form, "group_id", null);
                        let index = this.controls.findIndex(c => c.key == 'group');
                        this.controls.splice(index + 1, 0, {
                            key: 'org',
                            label: '组织名称',
                            component: 'a-input',
                            props: {
                                placeholder: '请输入组织名称',
                            },
                            rules: [{ required: true, message: '请输入组织名称', trigger: 'blur' }]
                        });
                    } else {
                        this.$set(this.form, "group_id", val);
                        let index = this.controls.findIndex(c => c.key == 'org');
                        if(index >= 0) {
                            this.controls.splice(index, 1);
                        }
                    }
                }
            },
            rules: [{ required: true, message: '请选择组织', trigger: 'change' }]
        },
        {
            key: 'tel',
            label: '咨询电话',
            component: 'a-input',
            props: {
                placeholder: '请输入咨询电话',
            },
            rules: [{ required: true, message: '请输入咨询电话', trigger: 'blur' }]
        },
        {
            key: 'img',
            label: '活动封面',
            component: 'form-upload',
            props: {
                limit: 9
            },
            rules: [{ required: true, message: '请上传活动封面', trigger: 'change' }]
        },
        {
            key: 'add_people_num',
            label: '线下人数',
            component: 'a-input-number',
            props: {
                placeholder: '请输入线下人数',
            },
            style: {
                width: '240px'
            }
        },
        {
            key: 'info',
            label: '活动介绍',
            component: 'a-textarea',
            props: {
                placeholder: '请输入活动介绍',
                rows: 4
            },
        },
        {
            key: 'order',
            label: '排序',
            component: 'a-input-number',
            props: {
                placeholder: '数字越小排位越前',
            },
            style: {
                width: '240px'
            }
        },
    ],
    // 详情接口expand字段
    expand: '?expand=enrollNum',
    init: {price: 0}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '活动时间',
                key: 'start_time'
            },
            {
                label: '活动地点',
                key: 'location'
            },
            {
                label: '活动人数',
                key: '',
                slot: entity => {
                    return <span>{entity.enrollNum+Number(entity.add_people_num)}</span>
                }
            },
            {
                label: '排序',
                key: 'order'
            },
            {
                label: '活动封面',
                key: 'img',
                slot: entity => {
                    return <div>
                        {entity.img ? entity.img.split(",").map(img => <img className="avatar" src={img}/>) : '无'}
                    </div>
                }
            },
            {
                label: '活动介绍',
                key: 'info'
            },
        ]
    },
    // 详情渲染字段
    component: 'ActivityDetail',
    beforeEdit: function(detail) {
        return new Promise(resolve => {
            detail.group = detail.group_id || 0;
            // 手动触发互动类型监听的change事件让表单展示;
            let item = this.detailForm.controls.find(c => c.key == 'group');
            if(item) {
                item.listeners.change.call(this.detailForm, detail.group);
            }
            resolve(detail);
        })
    },
    beforeUpdate: function(form) {
        return new Promise((resolve) => {
            if (form.group_id) {
                form.org = null;
            }
            resolve(form);
        });
    }
}

