import {getDurationText} from "../js/tool";

const courseTypes = [
    {key: 1, title: '政治类'},
    {key: 2, title: '文化类'},
    {key: 3, title: '娱乐类'},
    {key: 4, title: '生活知识类'},
]

const getCourseTypeText = function (type) {
    let res = courseTypes.find(p => p.key == type);
    return res ? res.title : null;
}

export default {
    name: '课程',
    listTitle: '课程列表',
    url: '/course', // rest接口表名
    query: '&sort=order,-id', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '发布时间', dataIndex: 'create_time'},
            {title: '课程名称', dataIndex: 'name'},
            {
                title: '时长',
                dataIndex: 'duration',
                customRender: text => {
                    return text ? <span>{getDurationText(text)}</span> : ''
                }
            },
            {
                title: '类别',
                dataIndex: 'type',
                customRender: text => {
                    return <span>{getCourseTypeText(text)}</span>
                }
            },
            {title: '排序', dataIndex: 'order'},
        ]
    },
    // 列表查询表单
    queryForm: [{
        key: 'name',
        label: '',
        component: 'a-input',
        props: {
            placeholder: '请输入课程名称查询',
        },
    }],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '课程名称',
            component: 'a-input',
            props: {
                placeholder: '请输入课程名称',
            },
            rules: [{ required: true, message: '请输入课程名称', trigger: 'blur' }]
        },
        {
            key: 'type',
            label: '类别',
            component: 'a-select',
            props: {
                options: courseTypes,
                placeholder: "请选择类别"
            },
            style: {
                width: '240px'
            },
            rules: [{ required: true, message: '请选择类别', trigger: 'change' }]
        },
        {
            key: 'video_url',
            label: '课程视频',
            component: 'form-upload',
            props: {
                mediaType: 'video'
            },
            listeners: {
                duration(val) {
                    this.$set(this.form, 'duration',  Math.round(val) + '');
                }
            },
            rules: [{ required: true, message: '请上传专辑封面', trigger: 'change' }]
        },
        {
            key: 'duration',
            label: '视频时长',
            component: 'a-input-number',
            props: {
                placeholder: '请输入视频时长（单位秒）例如1:30的视频时长为90',
            },
            style: {
                width: '240px'
            },
            rules: [
                { required: true, message: '请输入视频时长', trigger: 'change' },
                { min: 1, message: '视频时长输入不正确', trigger: 'change' }
            ]
        },
        {
            key: 'cover',
            label: '视频封面',
            component: 'form-upload',
            rules: [{ required: true, message: '请上传视频封面', trigger: 'change' }]
        },
        {
            key: 'order',
            label: '排序',
            component: 'a-input-number',
            props: {
                placeholder: '数字越小排位越前',
            },
            style: {
                width: '240px'
            },
            rules: [{  message: '', trigger: 'blur' }]
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '课程名称',
                key: 'name'
            },
            {
                label: '时长',
                key: 'duration',
                slot: entity => {
                    return entity.duration ? <span>{getDurationText(entity.duration)}</span> : ''
                }
            },
            {
                label: '类别',
                key: 'type',
                slot: entity => {
                    return <span>{getCourseTypeText(entity.type)}</span>
                },
            },
            {
                label: '排序',
                key: 'order'
            },
            {
                label: '课程视频',
                key: 'video_url',
                slot: entity => {
                    return <div class="video-preview">
                        <video poster={entity.cover} controls src={entity.video_url}></video>
                    </div>
                },
                span: 3
            },
        ]
    }
}
