const policyTypes = [
    {key: 1, title: '产业扶持'},
    {key: 2, title: '科技创新'},
    {key: 3, title: '人才创新'},
    {key: 4, title: '金融服务'},
    {key: 5, title: '综合政策'},
]

const getPolicyTypeText = function (type) {
    let res = policyTypes.find(p => p.key == type);
    return res ? res.title : null;
}

export default {
    name: '政策',
    listTitle: '政策列表',
    url: '/policy', // rest接口表名
    query: '&sort-id', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '发布时间', dataIndex: 'create_time'},
            {title: '标题', dataIndex: 'title'},
            {
                title: '类别',
                dataIndex: 'type',
                customRender: text => {
                    return <span>{getPolicyTypeText(text)}</span>
                }
            },
        ]
    },
    // 列表查询表单
    queryForm: [{
        key: 'title',
        label: '',
        component: 'a-input',
        props: {
            placeholder: '请输入标题查询',
        },
    }],
    // 新增/编辑查询表单
    items: [
        {
            key: 'title',
            label: '标题',
            component: 'a-input',
            props: {
                placeholder: '请输入标题',
            },
            rules: [{ required: true, message: '请输入标题', trigger: 'blur' }]
        },
        {
            key: 'type',
            label: '类别',
            component: 'a-select',
            props: {
                options: policyTypes,
                placeholder: "请先选择类别"
            },
            style: {
                width: '240px'
            },
            rules: [{ required: true, message: '请选择类别', trigger: 'change' }]
        },
        {
            key: 'review',
            label: '专业点评',
            component: 'a-textarea',
            props: {
                placeholder: '请输入专业点评',
                rows: 4
            },
        },
        {
            key: 'content',
            label: '内容',
            component: 'rich-editor'
        },
    ],
    // 详情接口expand字段
    expand: '?expand=teacher',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '标题',
                key: 'title',
                span: 2,
            },
            {
                label: '类别',
                key: 'tel',
                slot: entity => {
                    return <span>{getPolicyTypeText(entity.type)}</span>
                }
            },
            {
                label: '专业点评',
                key: 'review',
                span: 3
            },
            {
                label: '内容',
                key: 'content',
                slot: entity => {
                    return <div domPropsInnerHTML={entity.content}></div>
                },
                span: 3
            },
        ]
    },
    // beforeUpdate: function(form) {
    //     return new Promise(resolve => {
    //         if(form.content) {
    //             form.content = JSON.stringify(form.content);
    //         }
    //         resolve(form);
    //     })
    // },
    // beforeEdit: function(detail) {
    //     return new Promise(resolve => {
    //         try {
    //             if(detail.content) {
    //                 detail.content = JSON.parse(detail.content);
    //             }
    //         } catch (e) {
    //             console.error(e);
    //         }
    //         resolve(detail);
    //     })
    // },
}
