export default {
    name: '公告',
    listTitle: '公告列表',
    url: '/notice', // rest接口表名
    query: '&sort-id', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '发布时间', dataIndex: 'create_time'},
            {title: '标题', dataIndex: 'title'},
            {
                title: '图片',
                dataIndex: 'img',
                customRender: text => {
                    return <img class="avatar" src={text} />
                }
            },
        ]
    },
    // 列表查询表单
    queryForm: [{
        key: 'title',
        label: '',
        component: 'a-input',
        props: {
            placeholder: '请输入标题查询',
        },
    }],
    // 新增/编辑查询表单
    items: [
        {
            key: 'title',
            label: '标题',
            component: 'a-input',
            props: {
                placeholder: '请输入标题',
            },
            rules: [{ required: true, message: '请输入标题', trigger: 'blur' }]
        },
        {
            key: 'img',
            label: '封面',
            component: 'form-upload',
            rules: [{ required: true, message: '请上传封面图片', trigger: 'change' }]
        },
        {
            key: 'content',
            label: '内容',
            component: 'rich-editor'
        },
    ],
    // 详情接口expand字段
    expand: '?expand=teacher',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '标题',
                key: 'title',
                span: 2,
            },
            {
                label: '封面',
                key: 'img',
                slot: entity => {
                    return <img class="avatar" src={entity.img}/>
                }
            },
            {
                label: '内容',
                key: 'content',
                slot: entity => {
                    return <div domPropsInnerHTML={entity.content}></div>
                },
                span: 3
            },
        ]
    }
}
