export default {
    name: '讲师',
    listTitle: '讲师列表',
    url: '/teacher', // rest接口表名
    query: '&sort=-id', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                title: '头像',
                dataIndex: 'avatar',
                customRender: text => {
                    return <img class="avatar mini" src={text} />
                }
            },
            {title: '姓名', dataIndex: 'name'},
            {title: '职称', dataIndex: 'title'},
        ]
    },
    // 列表查询表单
    queryForm: [{
        key: 'name',
        label: '',
        component: 'a-input',
        props: {
            placeholder: '请输入讲师姓名查询',
        },
    }],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '姓名',
            component: 'a-input',
            props: {
                placeholder: '请输入姓名',
            },
            rules: [{ required: true, message: '请输入姓名', trigger: 'blur' }]
        },
        {
            key: 'title',
            label: '职称',
            component: 'a-input',
            props: {
                placeholder: '请输入讲师职称',
            },
            rules: [{ required: true, message: '请输入讲师职称', trigger: 'blur' }]
        },
        {
            key: 'avatar',
            label: '头像',
            component: 'form-upload',
            rules: [{ required: true, message: '请上传头像', trigger: 'change' }]
        },
        {
            key: 'info',
            label: '人物介绍',
            component: 'a-textarea',
            props: {
                placeholder: '请输入人物介绍',
                rows: 4
            },
        },
    ],
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '头像',
                key: 'avatar',
                span: 2,
                slot: entity => {
                    return <img class="avatar" src={entity.avatar}/>
                }
            },
            {
                label: '职称',
                key: 'title',
            },
            {
                label: '人物介绍',
                key: 'info',
                span: 3,
            }
        ]
    }
}
