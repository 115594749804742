export default {
    name: '人物事迹',
    listTitle: '人物事迹列表',
    url: '/story', // rest接口表名
    query: '&sort-id', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '姓名', dataIndex: 'name'},
            {
                title: '图片',
                dataIndex: 'img',
                customRender: text => {
                    return <img class="avatar" src={text} />
                }
            },
        ]
    },
    // 列表查询表单
    queryForm: [{
        key: 'name',
        label: '',
        component: 'a-input',
        props: {
            placeholder: '请输入姓名查询',
        },
    }],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '姓名',
            component: 'a-input',
            props: {
                placeholder: '请输入姓名',
            },
            rules: [{ required: true, message: '请输入姓名', trigger: 'blur' }]
        },
        {
            key: 'img',
            label: '图片',
            component: 'form-upload',
            rules: [{ required: true, message: '请上传图片', trigger: 'change' }]
        },
        {
            key: 'info',
            label: '事迹介绍',
            component: 'a-textarea',
            props: {
                placeholder: '请输入人物事迹介绍',
                rows: 4
            },
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '图片',
                key: 'img',
                span: 3,
                slot: entity => {
                    return <img class="avatar" src={entity.img}/>
                }
            },
            {
                label: '事迹介绍',
                key: 'info',
                span: 3
            },
        ]
    },
}
