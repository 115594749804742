export default {
    name: '群团',
    listTitle: '群团列表',
    url: '/group', // rest接口表名
    query: '&sort=order,-id', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {title: '群团名称', dataIndex: 'name'},
            {title: '排序', dataIndex: 'order'},
        ]
    },
    // 列表查询表单
    queryForm: [
        {
            key: 'name',
            label: '',
            component: 'a-input',
            props: {
                placeholder: '请输入群团名称查询',
            },
        },
    ],
    // 新增/编辑查询表单
    items: [
        {
            key: 'name',
            label: '群团名称',
            component: 'a-input',
            props: {
                placeholder: '请输入群团名称',
            },
            rules: [{ required: true, message: '请输入群团名称', trigger: 'blur' }]
        },
        {
            key: 'img',
            label: '群团图片',
            component: 'form-upload',
            props: {
                limit: 9
            },
            rules: [{ required: true, message: '请上传群团图片', trigger: 'change' }]
        },
        {
            key: 'info',
            label: '群团介绍',
            component: 'a-textarea',
            props: {
                placeholder: '请输入群团介绍',
                rows: 4
            },
        },
        {
            key: 'notice',
            label: '入会须知',
            component: 'a-textarea',
            props: {
                placeholder: '请输入入会须知',
                rows: 4
            },
        },
        {
            key: 'order',
            label: '排序',
            component: 'a-input-number',
            props: {
                placeholder: '数字越小排位越前',
            },
            style: {
                width: '240px'
            }
        },
    ],
    // 详情接口expand字段
    expand: '',
    init: {}, // 新增实体初始化字段
    // 详情渲染字段
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '群团介绍',
                key: 'info',
                span: 3
            },
            {
                label: '入会须知',
                key: 'notice',
                span: 3
            },
            {
                label: '排序',
                key: 'order'
            },
            {
                label: '群团图片',
                key: 'img',
                slot: entity => {
                    return entity.img ? <img class="avatar" src={entity.img}/> : <span>无</span>
                }
            },
        ]
    }
}
