export default {
    name: '横幅',
    listTitle: '横幅列表',
    url: '/banner', // rest接口表名
    query: '&sort=order,-id&expand=link', // rest接口query参数
    // 列表渲染列
    columns: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                title: '图片',
                dataIndex: 'img',
                customRender: text => {
                    return <img class="avatar" src={text} />
                }
            },
            {title: '排序', dataIndex: 'order'},
        ]
    },
    // 列表查询表单
    queryForm: [],
    // 新增/编辑查询表单
    items: [
        {
            key: 'img',
            label: 'banner图',
            component: 'form-upload',
            rules: [{ required: true, message: '请上传banner图', trigger: 'change' }]
        },
        {
            key: 'order',
            label: '排序',
            component: 'a-input-number',
            props: {
                placeholder: '数字越小排位越前',
            },
            style: {
                width: '240px'
            }
        },
    ],
    init: {type: 1},
    // 详情接口expand字段
    expand: '?expand=link',
    renderItems: ctx => {
        // eslint-disable-next-line no-unused-vars
        const h = ctx.$createElement
        return [
            {
                label: '排序',
                key: 'order'
            },
            {
                label: 'banner',
                key: 'img',
                slot: entity => {
                    return <img class="avatar" src={entity.img}/>
                }
            },
        ]
    }
}
